import React from 'react';
import { useState } from "react";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
//bring the global Filter@20230608
const GlobalFilter=({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    language
  }) =>{
    const {value:inputValue} = useSelector(state=>state.input) || '';

    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
      //pattern checking of the value
      // console.log('rows',preGlobalFilteredRows);




      //if it is 
      setGlobalFilter(value || undefined)
    }, 200);

    React.useEffect(()=>{
       if(!inputValue) return;
       setValue(inputValue);
       onChange(inputValue);
    },[inputValue,onChange]);

  
    return (
        <Row className='text-center p-1' style = {{minHeight:'10vh'}}>
                <div className = 'form-group mx-auto'>
                    <label className='float-left mb-3'>{ language==='en' ?'reference' :'NSK或者其它业务编号'} <sup>*</sup></label>
                    <input
                      className='form-control  mb-4 text-danger rounded'
                      style ={{minWidth:'300px',border:'1px solid #C4261D',padding:'10px 15px'}}
                      value={value || ""}
                      onChange={e => {
                        setValue(e.target.value.toUpperCase());
                        onChange(e.target.value.toUpperCase());
                      }}
                      placeholder={language==='en'?'typing...':'录入...'}
                      // disabled ={!show}
                    />
                </div>
        </Row>
    )
  }
  export default GlobalFilter;