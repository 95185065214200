import React, { useEffect, useRef } from 'react';
import MessageBubble from './MessageBubble';

function MessageList(props) {
  const {
    dataSource
   } = props;
 

  const messagesEnd = useRef(null);
  const scrollToBottom = () => {
    messagesEnd.current?.scrollIntoView({ behavior: 'smooth'});
  };

  useEffect(() => {
      scrollToBottom();    
  }, [dataSource]);

  return (
    <div className="overflow-auto mh-100 p-3 d-flex flex-column-reverse overflow-auto" >
        <div ref={messagesEnd}  style={{ float:"left", clear: "both" }}/>
        {
         dataSource?.length && dataSource.map((item, i) => (      
            <MessageBubble
              // eslint-disable-next-line react/no-array-index-key
              key={i + 1}
              data={item}
            />
          ))
        }
    </div>
  );
}


export default MessageList;
