import React  from 'react';
import {useSelector} from 'react-redux';

import { useNavigate} from 'react-router-dom';
import { Row } from "react-bootstrap";
import useMessage from '../hooks/useMessage';
// import PreEcho from '../components/PreEcho';
import SideBar from '../components/chats/SideBar';
import ChatInput from '../components/chats/ChatInput';
import MessageList from '../components/chats/MessageList';
import MessageHeader from '../components/chats/MesssageHeader';
import RenderTask from '../components/tasks/RenderTask'; //components/actions 
// import TaskTable from '../tables/TaskTable';

//manage=> service  
const Admin = ()=>{

    const [hide,setHide] = React.useState(false);
    
    //toggle the view
    const toggleView = ()=>{
          setHide(!hide);
    }
 
    const {userInfo} = useSelector(state=>state.userLogin);

    const navigate   = useNavigate();

    React.useEffect(()=>{
        if(!['STAFF','AGENT'].includes(userInfo?.role_type))
        {
             navigate('/login');
        }

    },[userInfo]);
     
    const {isProxy,error,disable,activePID,allPID,clickPID,inputKeyUp,getMessages,submitHandler} = useMessage();
    //init
    const data =  getMessages();

    let messages = data
            .sort((a,b)=> b.timestamp - a.timestamp)
            .filter(m=>m.from !== m.to).slice(0,20);
    


    return (
        <Row style = {{borderBottom:'1px solid #e2e2e2',borderTop:'1px solid #e2e2e2'}} className ='my-1 shadow'>
        <div className ='col-md-2 col-xs-12 p-2 text-danger' style={{height:'72vh',borderLeft:'1px solid #e2e2e2',borderRight:'1px solid #e2e2e2'}}>
            {<SideBar 
               users = {allPID}  
               clickPID = { clickPID } 
               messages = {data}
               toggle = {toggleView}
               
               />}
        </div>
        <div className ='col-md-10 col-xs-12' style = {{height:'68vh',borderRight:'1px solid #e2e2e2'}}>
             <div className = 'row'>
                  <div className ={`col-md-6 col-xs-12 p-1 ${hide?'d-none':'' }`} style = {{height:'72vh',borderRight:'1px solid #e2e2e2'}}>
                      <div className='d-flex flex-column'>
                          <MessageHeader PID = {activePID} />
               
                           <div className='p-2' style = {{height:'50vh',overflow:'auto',borderBottom:'1px solid #e2e2e2'}} >     
                                {messages?.length > 0 && (<MessageList dataSource={messages} />) }
                          </div>
                          <ChatInput submit = {submitHandler} keyUp = {inputKeyUp} disable = {disable} errMsg = {error}  isProxy = {isProxy}/>       
                      </div>
                  </div>
                  <div className ={`col-md-6 ${hide?'col-md-12':''} col-xs-12 p-0`}>
                       <RenderTask  toggle = {toggleView}/>
                  </div>
             </div>
        </div>
     </Row>
 
    );
}
export default Admin;